md-toast {
    position: fixed;
}

md-toast.md-error-theme div.md-toast-content{
    margin-top: 40px;
    color: white !important;
    background-color: #D32F2F !important;
}
md-toast.md-error-theme div.md-toast-content .md-button{
    background-color:#B71C1C !important;
}
md-toast.md-error-theme div.md-toast-content .md-toast-text{
    white-space: pre-line !important;
}
