//Fix for x-editable override
address {
    margin-bottom: 0;
}

//Add cursor to
[clickable] {
    cursor: pointer;
}

icon {
    //    display: inherit;
    //    text-align: center;
    //    line-height: 1;

    span,
    svg {
        margin: auto;
    }
}

// Fix z-index bug with page-guide
#tlyPageGuideWrapper #tlyPageGuideToggles {
    z-index: 100 !important;
}