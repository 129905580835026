.md-button {
    min-width: 48px;

    &.small {
        min-width: 0px;
        padding-left: .5rem;
        padding-right: .5rem;

        height: 28px;
        min-height: 28px;
        line-height: 28px;
    }

    i + *, svg + *, icon + * {
        margin-left: .5rem;
    }
}

md-list-item .md-icon-button {
    icon, .fa {
        font-size: 20px;
    }
}
