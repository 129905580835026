/* Stepper */

.stepper {
    &.horizantal {
        .material();
        .line-height(72px);
        
        padding: 0px 24px;
        background-color: white;

        @media (max-width: 34em) {
            .line-height(auto);
            
            padding: 8px;
            
            .step {
                margin: 16px;
            }
            
            .title {
                display: none;
            }
        }
    }
    
    .step {
        .line-height(28px);
        
        vertical-align: middle;
        display: inline-block;
        clear: both;
                
        padding: 0px 8px;
        
        
        &.active, &.complete {
            font-weight: 600;
            
            .icon {
                background-color: @color-primary;
            }
        }
        
        .icon {
            .line-height(28px);
            width: 28px;
            
            vertical-align: bottom;
            float: left;
            display: inline-block;
            text-align: center;
            border-radius: 100%;
            
            color: white;
            background-color: @color-secondary;
        }
        
        .title {
            padding-left: 8px;
        }
    }
    
    .divider {
        display: inline-block;
        width: 32px;
        height: 10px;
    }
}