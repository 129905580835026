.error-page {
    text-align: center;

    .http-error {
        margin: 1rem;
        padding: 0 2rem;

        .error-divider {
            border-bottom: 1px solid #d0d0d0;
            margin-bottom: .5rem;
        }

        .error-detail {
            h1.error-code {
                color: #40484f;
                font-size: 5rem;
                margin: 0;
            }

            .error-title {
                font-size: 1.75rem;
            }

            .error-message {
                font-size: 1rem;
            }
        }

        .error-actions {
            margin-top: .5rem;
        }
    }

    .error-reference {

    }
}
