@padding: 1rem;
@margin: 1rem;

[layout-padding-none] {
    padding: 0;
}

[layout-margin-none] {
    margin: 0;
}

.helper (@property; @extention; @value) {
    @{property}-@{extention}: @value !important;
}

.helperMulti (@property, @value) {
    &, &-a{
        .helper(@property; left; @value);
        .helper(@property; right; @value);
        .helper(@property; top; @value);
        .helper(@property; bottom; @value);
    }

    &-x {
        .helper(@property; left; @value);
        .helper(@property; right; @value);
    }

    &-l {
        .helper(@property; left; @value);
    }

    &-r {
        .helper(@property; right; @value);
    }


    &-y {
        .helper(@property; top; @value);
        .helper(@property; bottom; @value);
    }

    &-t {
        .helper(@property; top; @value);
    }

    &-b {
        .helper(@property; bottom; @value);
    }
}

.generateLayout (@property; @value: 1rem; @steps: 4; @i: 0) when (@i <= @steps) {
    .@{property}-0 when (@i = 0) {
        .helperMulti(@property, 0);
    }

    .@{property} when (@i = 1) {
        @adjustedValue: @value - (((@value / @steps) * @i) - (@value / @steps));
        .helperMulti(@property, @adjustedValue);
    }

    .@{property}-@{i} when (@i > 0) {
        @adjustedValue: @value - (((@value / @steps) * @i) - (@value / @steps));
        .helperMulti(@property, @adjustedValue);
    }

    .generateLayout(@property, @value, @steps, @i + 1);
}

.generateLayout(margin);
.generateLayout(padding);

[layout-attatch-top] {
    position: relative;
    top: -32px;
    z-index: 2;
}

.position-fixed (@top, @bottom, @right, @left) {
    position: fixed;
    z-index: 3;

    top: @top;
    bottom: @bottom;
    right: @right;
    left: @left;
}

[position-fixed-top] {
    .position-fixed(inherit, inherit, inherit, inherit);
}

[position-fixed-bottom] {
    .position-fixed(inherit, 0, inherit, inherit);
}

[position-fixed-bottom-right] {
    .position-fixed(inherit, 0, 0, inherit);
}

.line-height-reset {
    line-height: 1rem;
}
