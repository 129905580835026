md-menu-content {
    md-list-item.md-2-line.md-info {
        * > * {

        color: #8e8e8e;
//        text-transform: uppercase;
        font-size: 12px;
        }


        &, &::before {
            min-height: 48px !important;
        }
    }
}
