.btn-group.menu {
    
    position: relative;
    
    .dropdown-item {
        &, * {
            color: #373a3c !important;
        }

        &:hover {
            background-color: rgba(0, 0, 0, .1) !important;
        }

        &.active {
            background-color: @color-primary-muted !important;

            &, * {
                color: white !important;
            }
        }
    }
}

.dropdown-item {
    line-height: 1.5rem;
}
