#primary-nav {
    .md-toolbar-tools {
        * {
            color: white !important;
        }
    }
}

.nav-brand {
    position: relative;
    font-size: 24px;
    height: 60px;
    line-height: 60px;
    padding-right: 16px;
    color: white;

    &:hover {
        color: white;
    }

    object, img {
        padding: 8px 0px;
        height: 60px;
        line-height: 60px;
        background-color: transparent;
    }
}
