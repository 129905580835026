.progress-border {
    .ease-transition();
    
    width: 0;
    height: 3px !important;
    border-bottom: 3px solid lighten(@color-primary, 25%) !important;
}

progress[value] {
    height: 20;
    margin-bottom: 0;
    border-radius: 0px;
    
    &.red {
        @color: #f44336;
        color: @color;
        
        &::-moz-progress-bar {
            background-color: @color !important;
        }
    }
    
    &.orange {
        @color: #ffeb3b;
        color: @color;
        
        &::-moz-progress-bar {
            background-color: @color !important;
        }
    }
    
    &.yellow {
        @color: #ffeb3b;
        color: @color;
        
        &::-moz-progress-bar {
            background-color: @color !important;
        }
    }
    
    &.green {
        @color: #4caf50;
        color: @color;
        
        &::-moz-progress-bar {
            background-color: @color !important;
        }
    }
}

.progress-wrapper {
    position: relative;
}

.progress-wrapper .text {
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    -webkit-transform: translateY(-50%);
    text-align: center;
    width: 100%;
    z-index: 1;
}