.elevation (@level: 4px) {
    box-shadow: 0px (@level / 2) @level (@level / 10) rgba(0, 0, 0, .3);
}

.elevation-bottom (@level: 3px) {
    box-shadow: 0px @level @level (@level / 10) rgba(0, 0, 0, .3);
}

.border-radius(@radius: 3px) {
    border-radius: @radius;
}

.line-height (@height) {
    height: @height;
    line-height: @height;
}

.raised {
    .elevation(6px);
}

.no-border {
    border: none;
}

.divider {
    border-bottom: solid 1px rgba(0, 0, 0, .12);
}

.divider-top {
    border-top: solid 1px rgba(0, 0, 0, .12);
}
