// Variables

@body-bg: #fafafa;

@text-color-body: #333;
@font-color-secondary: #3a3a3a;
@text-color-muted: rgba(0, 0, 0, 0.54);
@text-color-input: #616161;

@color-primary: #0e4db1;
@color-primary-muted: lighten(@color-primary, 18%);
@color-secondary: #e8e8e8;
@color-success: #4caf50;
@color-danger: #FF3300;
@color-warning: #ff9100;

@primary-navbar-height: 56px;
@primary-navbar-color: @color-primary;

@secondary-navbar-width: 250px;
@secondary-navbar-color: darken(@color-primary, 10%);//#093372;

@toolbar-height: 46px;
@toolbar-color: white;

@container-width: 920px;

.color {
    &-primary {
        color: @color-primary;

        &-muted {
            color: @color-primary-muted;
        }
    }
    
    &-muted {
        color: @text-color-muted
    }
}

.color-body {
    color: @text-color-body;
}
