.donut {
    position: relative;
    margin-bottom: 8px;

    text-align: center;

    .label {
        position: absolute;
        top: calc(50% - 24px);
        width: 100%;
        font-size: 24px;
    }
}
