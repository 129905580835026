/* Lists */

.list-hover () {
    .ease-transition();
    
    &:hover, &.active {
        & {
            background-color: darken(@body-bg, 3%);
        }
    }    
}

ul.material {    
    list-style: none;
    padding: 8px 0px;
    
    .li (@element-height, @height-modifier: 1, @element-padding: 16px) {
        padding: 0px @element-padding 0px @element-padding;
        display: block;

        &:not(.plain) {
            .list-hover();
            .surface-touch(@element-height);
            
            &:before { //TODO fix hack
                left: 0px;
                width: calc(100% - 32px);
            }
        }
        
        &:last-child.divider {
            border: none;
        }
        
        .content {
            .content();
        }
    }
    
    &.scrolling {
        overflow-y: scroll;
        max-height: 400px;
    }
    
    &.condensed {
        li {
            &.single {
                .li(32px, 1);
            }

            &.double {
                .li(64px, 1.25);
            }
        }        
    }    
    
    &:not(.condensed) {
        li {
            &.single {
                .li(44px);
            }

            &.double {
                .li(72px, 1.25);
            }
        }        
    }
}