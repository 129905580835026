.md-badge {
    .md-badge(14px)
}

.md-badge.xl {
    .md-badge(22px)
}

.md-badge (@size)  {
    height: @size;
    min-width: @size;

    position: absolute;
    display: inline-block;
    padding: .25em .4em;
    line-height: 1;

    background-color: #FF5252;
    color: white;
    font-size: @size / 1.5;
    text-align: center;
    white-space: nowrap;
    vertical-align: baseline;
    border-radius: @size;

    &:not(.bottom) {
        top: .15rem;
    }

    &:not(.left) {
        right: -(@size / 2);
    }

    &.bottom {
        bottom: 0px;
    }

    &.left {
        left: .15rem;
    }
}
