/*
* Circle
*/
.circle (@size: 175px) {
    width: @size;
    height: @size;
    border-radius: 50%;
}

.circle {
    border-radius: 50%;
}

/*
 * Positioning
 * */
.pull-down {
    position: relative;
    bottom: 0;
}
