.alertMenu {
    width:300px;
    max-height:340px;
    min-height:100px;

}
.alertItem {
    min-height:40px;
    padding: 0px;
    margin:0px;
    margin-bottom: 6px;
    padding-left: 6px;
    padding-right: 22px;
}
.alertItem:hover {
    background-color: #f0f0f5;
}
.alertItemTitle {
    font-size: 14px;
    font-weight: 600;
}
.alertItemText {
    font-size: 12px;
}
.alertItemDate {
    font-size: 12px;
    color:gray;
}
.alertHeading {
    font-size: 18px;
    padding-left: 18px;
}

