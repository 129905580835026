@import "button";
@import "button-group";
@import "card";
@import "chips";
@import "content";
@import "dialog";
@import "layout";
@import "icon";
@import "input";
@import "menu";
@import "modal";
@import "nav";
@import "progress";
@import "toolbar";
@import "tabs";
@import "toast";
@import "list";

loader {
//    display: flex;
}

body {
    font-size: .75rem;
}

h1, .h1 {
    font-size: 2rem;
    font-weight: 600;
    letter-spacing: 2px;
    margin-bottom:6px;
}
h2, .h2 {
    font-size: 1.75rem;
    letter-spacing: 1px;
    margin-bottom:4px;
}

h3, .h3 {
    font-size: 1.5rem;
    letter-spacing: 1px;
    margin-bottom:2px;
}

h4, .h4 {
    font-size: 1.25rem;
    margin-bottom:2px;
}

h5, .h5 {
    font-size: 1rem;
    margin-bottom:2px;
}

h6, .h6 {
    font-size: .75rem;
    font-weight: 600;
    margin-bottom:2px;
}

input {
    font-size: larger;
}

label {
    font-size: larger;
    margin-bottom: 0px;
}

.tooltip-wrapper {
    position: relative;
    z-index: 1;
    pointer-events: auto;
}
