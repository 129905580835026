//Variables
@import "variables";

//Utils
@import "util";
@import "transition";
@import "layout";

.primary-color {
    color: @color-primary;
}

.secondary-color {
    color: @color-secondary;
}

// Mixins

.anchor-reset () {
    color: @text-color-body;
    
    &:hover, &:focus, &:active {
        text-decoration: none;
        outline: none;
        color: inherit;
    }
}

.material (@border-radius: 3px, @elevation: 3px) {
    .elevation(@elevation);
    border-radius: @border-radius;
}

body {
    background-color: @body-bg; 
}

//Text util


a {
    .anchor-reset();
    .ease-transition();
    
    color: lighten(@text-color-body, 15%);
    
    &:hover, &:focus, &:active {
        color: @color-primary;
//        
//        & > * {
//            color: @text-color-body;
//        }
    }
}

/* Misc */

.blockquote {
    font-size: 1rem;
}

footer {
    .text-muted();
}

/* Views */

.view-pane {
    .ease-transition();
}

/* Avatars */

.avatar-img {
    border-radius: 100%;
    height: 40px;
    width: 40px;
}

.avatar, .avatar * {
    height: 40px;
    width: 40px;
    border-radius: 3px;
    background-color: white;
    
    *, & {
        font-size: 26px;
        text-align: center;
        padding: 0px 2px !important;
        color: @text-color-muted !important;  
    }

    &img {}
}

// Components

@import "menu";
@import "form";
@import "alert";
@import "table";
@import "button";
@import "list";
@import "progress";
@import "stepper"; //TOOD: finish
