md-card-actions {
    md-card-icon-actions {
        margin-right: 0;

        .md-icon-button:last-child {
            margin-right: 0 !important;
        }
    }
}

md-card {
    md-toolbar > * {
        font-size: 1.4rem !important;
    }
	
	md-card-header-text {
		h3 {
			font-size: 1.5rem !important;
		}
	}

    md-card-actions {
        margin: 0px;
    }
}
