.messages {
//    padding: 1rem;
//    padding-bottom: 0rem;

    & > *:last-child {
        .message {
//            margin-bottom: 4px !important;
        }
    }
}

.message {
    .border-radius();
    .elevation(1px);

    background-color: @color-secondary;
    margin-bottom: 8px;

    clear: both;

    & > * {
        padding: 8px;
    }

    .divider {
        border-top: 1px solid white;
        padding: 0;
    }

    .left {
        .text-muted();

        padding-right: 16px;

        text-transform: uppercase;
        font-size: 12px;

        position: relative;

        .action {
            font-size: 16px;

            .menu > .trigger {
                position: absolute;
                right: 5px;
                bottom: -5px;
            }

            &, & * {
                color: white;
            }
        }
    }

    .right {
        line-height: 1.15rem;
        vertical-align: middle;
        margin-bottom: 4px;
    }

    &.active {
        color: white;
        background-color: lighten(@color-primary, 20%);

        .left {
            color: darken(white, 10%) !important;
        }
    }
}
