@border-radius: .25rem;

button-group {
    display: inline-block;

    .button-group {
        display: inline-block;
        border-radius: @border-radius;
        box-shadow: 0 2px (@border-radius / 2) 0 rgba(0, 0, 0, 0.26);

        .md-button {
            & {
                margin: 0 0;
                box-shadow: none !important;
            }

            &:not([disabled]):hover {
                background-color: rgba(193, 193, 193, 0.96);
                color: rgba(44, 65, 164, 0.96);
                transition: 0.3s;
            }

            &:first-child {
                border-radius: @border-radius 0 0 @border-radius;
            }

            &:not(:first-child) {
                border-radius: 0;
                border-left: 1px solid rgba(230, 230, 230, 0.96);
            }

            &:last-child {
                border-radius: 0 @border-radius @border-radius 0;
            }
        }
    }
}
