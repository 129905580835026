.text-muted () {
    color: @text-color-muted !important;
}

.text-muted {
    .text-muted();
}

.clickable {
    cursor: pointer;
}
.text-overlay {
    position:absolute;
    bottom:0px;
    background: rgba(0, 0, 0, 0.55);
    color:white;
    padding:8px;width:100%;
    min-height:56px
}

.truncate {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
}
