@keyframes surface-touch {
    0% {
        width: 0%;
        opacity: 0;
    }

    50% {
        width: 50%;
        opacity: .25;
    }

    100% {
        width: 100%;
    }
}

.surface-touch (@height: 100%) {
    .animation () {
        animation: surface-touch .35s 1;
    }

    overflow: hidden;

    &:before {
        .ease-transition();

        width:auto;
        position: absolute;
        left: 0px;
        width: 100%;
        height: @height;
        pointer-events: none;

        content: "";
        display: block;
        background: #616161;
        opacity: 0;
        z-index: 1;
    }


    &:not(.touch-watched) {
        &:active {
            .animation();
        }
    }

    &.touch-watched {
        &.touch-active {
            &:before {
                .animation();
            }
        }
    }
}
