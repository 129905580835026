//Variables and utilities
@import "variables";
@import "util/index";

@import "animation/index";

//Components
@import "button/button";
@import "dashboard/dashboard";
@import "metric/index";
@import "content/index";
@import "form/index";
@import "chip/index";
@import "ng-material-datetimepicker/index";
@import "message/message";
@import "badge/badge";
@import "bootstrap-overrides/index";
@import "ng-material-overrides/index";
@import "layout/layout";
@import "input/input";
@import "nav/nav";
@import "file/index";

.logo {
    max-width: 200px !important;
}
