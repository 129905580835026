/* Data Tables */

table.material {
    background-color: white;
    width: 100%;
    overflow-x: hidden;
    
    thead {
        th {
            font-size: 12px;
            color: rgba(0, 0, 0, 0.54);
            height: 54px
        }        
    }
    
    tbody {
        tr {
            .list-hover();
            height: 48px;
            
            &.active {
                background-color: darken(#F5F5F5, 10%);
            }
                        
            &.primary {
                &, & > td:not(.card) {
                    background-color: lighten(@color-primary, 25%);
                    border: none !important;
                    color: white;
                }
            }
            
            &.danger {
                &, & > td:not(.card) {
                    background-color: lighten(@color-danger, 25%);
                    border: none !important;
                    //color: white;
                }
            }
            
            &:last-child {
                & > * {
//                    border: none;
                }
            }
        }
    }
    
    th, td {
        padding-right: 24px;
        border-bottom: 1px solid #e0e0e0;

        font-size: 13px;
        color: rgba(0, 0, 0, 0.87);
        font-weight: 400;

        word-wrap: none;
        overflow-x: hidden;
        
        &:first-child {
            padding-left: 24px;
        }
        
        &:last-child {
            padding-right: 24px;
        }
        
        &.addon {
            border-right: 1px solid #e0e0e0 !important;
            padding: 0px 16px;
            width: 0;
            background-color: darken(white, 5%);
            text-align: center;
            
            & + *:not(.check) {
                padding-left: 8px;
            }
        }
        
        &.check {
            text-align: center;
            padding: 0px 8px;
            width: 48px;
        }
        
        &.truncate {
            white-space: nowrap; 
            text-overflow: ellipsis; 
            overflow: hidden; 
            max-width: 1px;
        }
        
        &.select {
            max-width: 20px;
        }       
        
        &.action {
            text-align: center;
            white-space: nowrap;
        }
        
        &.text {
            text-align: left;
        }

		&.center {
			text-align: center;
		}
        
        &.numeric {
            text-align: right;
            white-space: nowrap;
        }
    } 
}
