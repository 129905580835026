.search-select-header>input {
    border: none;
    outline: none;
    width: 100%;
    padding: 8px;
    box-shadow: 0 1px 0 0 rgba(0, 0, 0, 0.1), 0 0 0 0 rgba(0, 0, 0, 0.14), 0 0 0 0 rgba(0, 0, 0, 0.12);
}

.input-search {
    & input {
        background-color: white;
        border-radius: 15px;
        border: 1px solid lightgray;
        padding-left: .5rem;
    }
}

.input-search.input-focused {
    & label {
        display: none;
    }
}