@ringColor: grey;

.ring-pulse {
    pointer-events: none;

    height: 18px;
    width: 18px;

    position: absolute;

    &:before {
        content: "";
        display: block;
        border: 2px solid @ringColor;
        -webkit-border-radius: 50%;
        height: 20px;
        width: 20px;
        position: absolute;
        left: -8px;
        top: -8px;
        -webkit-animation: pulsate 1s ease-out;
        -webkit-animation-iteration-count: infinite;
        -webkit-animation-delay: 0.15s;
        opacity: 0.0;
    }

    &:after {
        content: "";
        display: block;
        border: 2px solid @ringColor;
        -webkit-border-radius: 50%;
        height: 40px;
        width: 40px;
        position: absolute;
        left: -18px;
        top: -18px;
        -webkit-animation: pulsate 1s ease-out;
        -webkit-animation-iteration-count: infinite;
        -webkit-animation-delay: 0.3s;
        opacity: 0.0;
    }
}

@-webkit-keyframes pulsate {
    0% {
        -webkit-transform: scale(0.1, 0.1);
        opacity: 0.0;
    }

    50% {
        opacity: 1.0;
    }

    100% {
        -webkit-transform: scale(1.2, 1.2);
        opacity: 0.0;
    }
}
