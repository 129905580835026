.specContent {
    border-left: 6px solid lightgray;
    margin-left: 26px;
    padding:4px;
}
.specDescription {
    padding: 6px;
    background: whitesmoke;
    margin-left: 16px;
}
.titleBox h5, .titleBox h3, .titleBox h2 {
    color: rgb(2,119,189);
}
.controlHeading {
    border-bottom: 1px solid;
    border-color: lightgray;
}
