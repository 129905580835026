.circle (@size: 175px) {    
    width: @size;
    height: @size;
    border-radius: 50%;
}

.elevation (@level: 4px) {
    box-shadow: 0px (@level / 2) @level (@level / 10) rgba(0, 0, 0, .3);
}

.elevation-bottom (@level: 3px) {
    box-shadow: 0px @level @level (@level / 10) rgba(0, 0, 0, .3);
}

.border-radius(@radius: 3px) {
    border-radius: @radius;
}

.line-height (@height) {
    height: @height;
    line-height: @height;
}

.raised {
    .elevation(6px);
}

.no-border {
    border: none;
}

.divider {
    border-bottom: solid 1px rgba(0, 0, 0, .12);
}

.divider-top {
    border-top: solid 1px rgba(0, 0, 0, .12);
}

/*
 * Positioning
 * */

.pull-down {
    position: relative;
    bottom: 0;
}


//Text

.text-muted () {
    color: @text-color-muted !important;
}

.text-muted {
    .text-muted();
}

.text-small {
    font-size: 65%;
}

//'Content' for cards, lists, ect

.content () {
    .anchor-reset();

    height: @element-height;
    vertical-align: middle;
    display: table;

    & > * {
        white-space: nowrap;
        display: table-cell;

        & + * {
            padding-left: 16px;

            &.right:last-child {
                width: 100%;
                text-align: right;
            }
        }
    }



    .icon {
        min-width: @element-height;
        line-height: @element-height + 8px;
        vertical-align: top;

        text-align: center;

        &.fill {
            font-size: @element-height;
        }

        .btn {
            position: relative;
            bottom: 8px;
            left: 8px;

            font-size: 20px;
        }
        
        img {
            height: @element-height / 1.7;
            border-radius: 100%;
        }
    }

    .text {
        height: @element-height;
        vertical-align: middle; 
        width: 100%;

        span {
           display: block;
           line-height: 20px;
           height: 20px;

           word-wrap: break-word;
           overflow: hidden;

           &.primary {
               font-size: 16px;
           }

           &.secondary {
               .text-muted();
               font-size: 12px;
           }
       }
    }
}
