.risk-metrics {
	.risk-metric {
		margin: 0px 1px;
		padding: 4px 0px;

		background-color: rgb(230, 230, 230);

		.title {
			text-align: center;
			width: 100%;
			margin-bottom: 1px;
			color: white;
			background-color: lighten(#0e4db1, 10%);
			margin-top: -3px;

			&>* {
				margin: .5rem 0;
			}
		}

		.value {
			font-size: 2.5rem;

			.sub {
				padding-left: .5rem;
				font-size: 1rem;

				.icon {
					text-align: center;
					vertical-align: bottom;
					font-size: 1rem;
					line-height: 1;
				}
			}
		}

		.title {
			font-size: 1rem;
		}
	}
}