/*
 * Apply various fixes to toolbars within cards
 * */
md-card, md-dialog {
    md-toolbar {
        border-top-left-radius: .15rem;
        border-top-right-radius: .15rem;

        &, .md-toolbar-tools {
            height: 48px;
            min-height: 48px;
        }
    }
}

nav-toolbar {
    line-height: 64px;
}

md-toolbar.md-hue-3 {
    color: white !important;
}

md-toolbar.page-header {
    background: white;
    color: inherit;
    box-shadow: 0px 3px 3px 0.3px rgba(0, 0, 0, 0.3);
    margin-bottom:8px;
}

toolbar-title {
//    line-height: 200%;
    padding-left:28px;
    font-size: 24px;
}

toolbar-filter, toolbar-filter.md-toobar-tools {
    background: whitesmoke;
    padding: 8px;
    font-size: .75rem !important;

    md-select {
        margin-top: 0px;
        margin-bottom: 0px;
    }
}

.toolbar-title {
    padding-top:6px;
    padding-left:28px;
    font-size: 24px;
}

md-breadcrumbs {
//    vertical-align: super;
    height: 100%;

    * {
        color: #848484 !important;
        font-size: 1rem;

        &:not(:last-child) {
            color: rgba(0,0,0,0.87) !important;

            &::after {
                padding-left: .5rem;
                content: '>';
            }
        }
    }
}
