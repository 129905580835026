@activeBackgroundColor: rgb(1, 87, 155);
@backgroundColor: #e2e2e2;
@inactiveBackgroundColor: fade(darken(@backgroundColor, 50%), 60%);

.dashboard {
    .dashboard-categories {
        background-color: @backgroundColor;

        .dashboard-category {
            color: white;
            background-color: @inactiveBackgroundColor;
            border-radius: .25rem;
            transition: all linear 0.1s;

            .icon {
                .md-badge {
                    background-color: @activeBackgroundColor;
                }
            }

            &.active {
                .icon {
                    .md-badge {
                        background-color: darken(@backgroundColor, 10%);
                    }
                }

                background-color: @activeBackgroundColor;
            }

            &:not(.active) {
                .icon {
                    .md-badge {
                        background-color: @activeBackgroundColor;
                    }
                }
            }

            .icon {
                &:not(.center) {
                    text-align: left;
                }

                &.center {
                    text-align: center;
                }

                font-size: 48px;

                span {
                    vertical-align: text-bottom;
                }

                & > *:nth-child(2) {
                    font-size: 20px;
                }
            }
        }
    }

    .dashboard-metrics {
        background-color: lighten(@backgroundColor, 10%);
        padding: .5rem;
    }

    .dashboard-items {

    }
}
