/* Buttons */

.btn {
    &:not(.no-hover) {
        .ease-transition();
    }
    
    font-size: 14px;
    text-transform: uppercase;
    text-align: center;
    border: none;
    border-radius: 3px;
    
    height: 32px;
    line-height: 32px;
    
    display: inline-block;
    overflow-x: hidden;
    
    margin: 8px 0px;
    
    & + & {
        margin-left: 8px;
    }

    &-block {
        display: block !important;
        //margin: 8px 16px;
        width: auto;
    }
    
    &.btn-icon {
        font-size: 18px;
    }
    
    &.btn-action {
        &.fixed {
            position: fixed;
            bottom: 0px;
            right: 8px;
        }
        
        &-primary {
            .btn-action(@color-primary);
        }
        
        &-secondary {
            .btn-action(@color-secondary, @font-color-secondary);
        }
        
        &-success {
            .btn-action(@color-success);
        }

        &-danger {
            .btn-action(@color-danger);
        }

        &-warning {
            .btn-action(@color-warning);
        }         
    }
    
    &.btn-flat {
        &-primary {
            .btn-flat(@color-primary);
        }
        
        &-secondary {
            .btn-flat(darken(@color-secondary, 20%));
        }
        
        &-success {
            .btn-flat(@color-success);
        }

        &-danger {
            .btn-flat(@color-danger);
        }

        &-warning {
            .btn-flat(@color-warning);
        }         
    }
    
    &.btn-raised {
        &-primary {
            .btn-raised(@color-primary);
        }
        
        &-secondary {
            .btn-raised(@color-secondary, @font-color-secondary);
        }
        
        &-success {
            .btn-raised(@color-success);
        }

        &-danger {
            .btn-raised(@color-danger);
        }

        &-warning {
            .btn-raised(@color-warning);
        }   
    }
}

.btn-action (@base-color, @font-color: white) {
    .elevation();
    .line-height(56px);
    
    width: 56px;
    border-radius: 100%;
    text-align: center;
    font-size: 24px;
    
    & .fa {
        vertical-align: text-top;
    }

    &.mini {
        .line-height(30px);
        width: 30px;
        font-size: 20px;
    }    
    
    &:disabled, &.disabled {
        background-color: lighten(@base-color, 5%);
        color: fade(@font-color, 80%);
    }
    
    &:not(:disabled):not(.disabled) {
        background-color: @base-color;
        color: @font-color;
        
        &:active, &:hover, &:focus {
            .elevation(3px);
            background-color: darken(@base-color, 5%);
        }
    }
}

.btn-flat (@base-color) {
    color: @base-color;
    padding: 0px 8px 0px 8px;
    
    &:disabled, &.disabled {
        background-color: lighten(@color-secondary, 5%);
        color: @base-color;
    }
    
    &:not(:disabled):not(.disabled):not(.no-hover) {
        &:active, &:hover, &:focus {
            background-color: fade(@base-color, 15%);
        }
    }
}

.btn-raised (@base-color, @font-color: white) {    
    .elevation(1px);
    
    padding: 0px 16px 0px 16px;
    min-width: 64px;
    
    &:disabled, &.disabled {
        background-color: lighten(@base-color, 5%);
        color: fade(@font-color, 80%);
    }
    
    &:not(:disabled):not(.disabled) {
        background-color: @base-color;
        color: @font-color;
        
        &:active, &:hover, &:focus {
            .elevation(3px);
            cursor: pointer;
            background-color: darken(@base-color, 5%);
        }
    }
}


/*
 * TODO: Clean up
 * */

.btn-group-justified {
    display: table;
    width: 100%;
    table-layout: fixed;
    border-collapse: separate;
    //padding: 0px 16px;
    //padding: 0px 16px;
}

.btn-group-justified.btn-group-auto {
    table-layout: auto;
}

.btn-group-justified > .btn,
.btn-group-justified > .btn-group {
    display: table-cell;
    float: none;
    width: 1%;
    margin: 0 !important;
    
    &:not(:first-child) {
        .btn {
            border-left: none;
            border-top-left-radius: 0px;
            border-bottom-left-radius: 0px;
        }
    } 
    
    &:not(:last-child) {
        .btn {
            border-right: none;
            border-top-right-radius: 0px;
            border-bottom-right-radius: 0px;
        }
    }
}

.btn-group-justified > .btn-group .btn {
    width: 100%;
}

.btn-group-justified > .btn-group .dropdown-menu {
    left: auto;
}
