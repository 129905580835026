
md-progress-linear[md-thick] {
    height:10px;

    .md-container {
        height:10px;
    }

    .md-container .md-bar {
        height:10px;
    }
}
