.metric {
    text-align: center;

    .measure {
        font-size: 38px;
        text-align: center;
        height: 50px; //todo: fix ths
        & > * {
            display: block;
            margin: 0 auto;
        }
    }

    .description {
        position: relative;
        bottom: 4px;

        font-size: 14px;
    }
}
