md-list[condensed] {
    md-list-item, .md-list-item-inner {
        min-height: 0px !important;
        height: 40px !important;
    }

    md-checkbox {
        margin-top: 2px !important;
        margin-bottom: 2px !important;
    }
}

md-list {
    md-item-content {
        .icon {
            width:32px;
        }
    }

    .md-avtar-icon {
        margin-top: 8px;
        margin-bottom: 8px;
        margin-right: 16px;

        height: 40px;
        width: 40px;
        font-size: 2rem;
        text-align: center;
    }
}
