.content {
    .content();
}

.content-small {
    .content(22px);
}

.content-large {
    .content(28px, 24px, 16px);
}

.content (@element-height: 28px, @primary-size: 16px, @secondary-size: 12px) {
    //.anchor-reset();

    height: @element-height;
    vertical-align: middle;
    display: table;
    width: ~"calc(100% - 16px)";

    & > * {
        white-space: nowrap;
        display: table-cell;

        & + * {
            padding-left: 16px;

            &.right:last-child {
                width: 100%;
                text-align: right;
            }
        }
    }

    .right {
//        float: right;
        text-align: right;
    }

    .icon {
        font-size: @element-height;
        width: @element-height;
        line-height: @element-height;
        height: @element-height;

        vertical-align: -moz-middle-with-baseline;
        text-align: center;

        &.fill {
            vertical-align: top;
            height: @element-height;
            font-size: @element-height;
        }

        .btn {
            position: relative;
            bottom: 8px;
            left: 8px;

            font-size: 20px;
        }

        img {
            height: @element-height * 1.5;
            border-radius: 100%;
            top: 3px;
            position: relative;
        }
    }

    content-icon {
        width: @element-height;
    }

    .text {
        height: auto;
        vertical-align: middle;
        width: 100%;

       & > span {
           display: block;
           line-height: 20px;
           height: 20px;

           word-wrap: break-word;
           overflow: visible;

           &.primary {
               font-size: @primary-size;

               &.fill {
                   height: @element-height;
                   font-size: @element-height;
                   vertical-align: baseline;
               }
           }

           &.secondary {
               .text-muted();
               font-size: @secondary-size;
           }
       }
    }
}
