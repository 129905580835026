.alert.material {
    .border-radius();
    .ease-transition(.5s);
    
//    border: none;
    background-color: darken(white, 1.5%);
    
    &:hover {
        background-color: white;
    }
}