//Base color
@baseColor: #FDD835;

//Fade colors for various states
@fade1Color: fade(@baseColor, 80%);
@fade2Color: fade(@baseColor, 60%);
@fade3Color: fade(@baseColor, 20%);

//Z-Index fix
.tlypageguide_toggle {
    z-index: 100;
}

//Styles for action buttons
#tlyPageGuideWrapper #tlyPageGuideMessages span,
.tlypageguide-open #tlyPageGuideWrapper .tlypageguide_toggle.tlyPageGuideToggleActive,
#tlyPageGuideWrapper #tlyPageGuideMessages .tlypageguide_close {
    background-color: rgb(1, 87, 155);
}

//Styles for  outline/shadow for target element
.tlypageguide_shadow {
    box-shadow: none;
    background-color: @fade3Color  !important;

    //Style for inactive step "number"
    .tlyPageGuideStepIndex {
        .inactiveStep();
    }

    //Stype for active/hovered target element
    &:hover,
    &.tlypageguide-active {
        background-color: @fade2Color  !important;

        //Style for step "number"
        &>.tlyPageGuideStepIndex {
            .activeStep();
        }
    }
}

//Mixin for styling a step "number"
.step(@color) {
    background-color: @color  !important;

    //Style for top, bottom, left and right step "arrow"
    &.tlypageguide_top,
    &.tlypageguide_bottom,
    &.tlypageguide_left,
    &.tlypageguide_right {
        &::after {
            border-top-color: @color  !important;
        }
    }

    //Style for bottom step "arrow"
    &.tlypageguide_bottom {
        &::after {
            border-bottom-color: @color  !important;
        }
    }
}

//Mixin for inactive step "number"
.inactiveStep() {
    .step(@fade1Color);
}

//Mixin for active step "number"
.activeStep() {
    .step(@baseColor);
}