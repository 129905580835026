/*
 * Font styles
 * */
@import "font.less";

/*
 * Google's Material Design implementation
 * */
@import "material-strap/index.less";

/*
 * Angular.js ng-cloak directive fix
 * */
[ng\:cloak], [ng-cloak], [data-ng-cloak], [x-ng-cloak], .ng-cloak, .x-ng-cloak, .ng-hide {
    display: none !important;
}

/*
 * Printing
 * */
@media print {
    nav.material, footer {
        &, & > * {
            display: none !important;
        }
    }
}

.md-green {
	background-color: #37a043 !important;
	color: white !important;
}

button-group .md-button.md-warn.md-raised {
    color: rgba(255,255,255,0.87) !important;
    background-color: rgb(198,40,40) !important;
}

button-group .md-button.md-primary.md-raised {
    color: rgb(255,255,255) !important;
    background-color: rgb(2,119,189) !important;
}
