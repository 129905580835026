@import "fixes";
@import "colors";
@import "text";
@import "shape";
@import "border";
@import "pageguide";

.avatar {
	width: 5rem;
	height: 5rem;
}

.image-overlay {
	position: relative;
	text-align: center;
	color: white;
	font-size: 1rem;

	.text {
		position: absolute;
		top: 50%;
		left: 45%;
		transform: translate(-50%, -50%);

		&.medium {
			font-size: 1.5rem;
			left: 50%;
		}

		&.large {
			font-size: 2rem;
			left: 50%;
		}
	}
}

.pointer {
	cursor: hand;
	cursor: pointer;
}