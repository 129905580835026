.dtp table.dtp-picker-days tr > td > a.selected,
.dtp table.dtp-picker-days tr > td > a.selected.hilite,
.dtp div.dtp-date, .dtp div.dtp-time, .dtp .dtp-hand.on,
.dtp .dtp-actual-meridien a.selected,
.dtp .dtp-picker-time > a.dtp-select-hour.selected {
  background: @color-primary-muted;
}

.dtp table.dtp-picker-days tr > td > a.hilite:not(.selected),
.dtp div.dtp-actual-time.p60 span.selected {
  color: @color-primary-muted;
}

.dtp div.dtp-year-btn, .dtp div.dtp-actual-year, .dtp div.dtp-actual-maxtime {
  color: whitesmoke;
}

.dtp > .dtp-content > .dtp-date-view > header.dtp-header {
  background: @color-primary;
}

md-menu-content.dtp-month-list {
  background-color: whitesmoke;
}

md-menu-content.dtp-year-list {
  background-color: whitesmoke;
};
