//Adds padding to tab contents
md-tabs[md-content-padding] {
    md-tab-content {
        padding: 8px;
    }
}

md-tabs[md-right-tabs] {
    md-pagination-wrapper {
        left: auto !important;
        right: 0 !important;
    }
}

md-tab-content {
    overflow: hidden;
    background: white;
}

md-tabs.scroll {
    md-tab-content {
        overflow: scroll;
    }
}

md-tabs-wrapper {
    background: white;
}

md-tabs[md-hide-tabs] {
    md-tabs-canvas {
        display: none;
    }

    md-tabs-content-wrapper {
        top: 0px;
    }

    md-progress-linear {
        height: 10px;
    }

    md-progress-linear .md-container {
        height: 10px;
    }

    md-progress-linear .md-container .md-bar {
        height: 10px;
    }

}