#dashboardHeader {
    height: 140px;
    background-position: left top;
    background-image: url('/assets/dashboard/img/cso-dashboard-banner.jpg');
    padding-left: 12px;
}
.dashboardContainerBox {
    height: 100px;
    color: aliceblue;
    padding:4px;
    padding-left: 8px;
    position:relative;
    z-index: 1;
}
.dashboardPeopleBox:before {
    content: "\f0c0";
    font-family: FontAwesome;
    font-size: 80px;
    right:2px;
    position:absolute;
    top:0;
    color: #334B5E;
    z-index: -1;
 }
.dashboardPlacesBox:before {
    content: "\f1ad";
    font-family: FontAwesome;
    font-size: 80px;
    right:2px;
    position:absolute;
    top:0;
    color: #5D335E;
    z-index: -1;
 }
.dashboardTechBox:before {
    content: "\f108";
    font-family: FontAwesome;
    font-size: 80px;
    right:4px;
    position:absolute;
    top:-4;
    color: #5E4733;
    z-index: -1;
 }
.dashboardVendorBox:before {
    content: "\f0d1";
    font-family: FontAwesome;
    font-size: 80px;
    right:12px;
    position:absolute;
    top:-10;
    color: #365E33;
    z-index: -1;
 }
.dashboardHomeBox:before {
    content: "\f015";
    font-family: FontAwesome;
    font-size: 50px;
    position:absolute;
    bottom:10px;
    color: #5D5D5D;
    z-index: -1;
 }
.dashboardBoxArrow {
    position:absolute;
    z-index: 3;
    right: -20px;
    opacity: 0.7;
    font-size: 100px !important;
}

