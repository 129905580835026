.container {
    max-width: @container-width;
}

.material-container {
    transition: left 0.2s;
    
    margin-right: 32px;
    
    min-height: 100%;
    min-width: 100%;
    position: relative;
    top: 0;
    left: 0;
    overflow: hidden;
    
    z-index: 0;
    background-color: @body-bg;
    
    &:before {
        transition: background-color 0.2s;
        
        position: absolute;
        left: 0;
        top: 0;

        width: 100%;
        height: 100%;
        
        content: "";
        z-index: -1;
        display: block;
        
        background-color: rgba(0, 0, 0, 0);
    }
    
    .material-content {
        margin-top: 16px;
    }
}
