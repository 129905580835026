/* Chips */

@height: 32px;

.chips {
    line-height: 1.5rem;

    & > :not(:last-child) {
        margin-right: 6px;
    }
}

.chip {
    .chip(#e0e0e0, #333);

    &.warning {
        .chip(@color-warning, white);
    }

    &.success {
        .chip(@color-success, white);
    }

    &.danger {
        .chip(@color-danger, white);
    }

    &.cvss-critical {
        .chip(#990000, white);
    }

    &.cvss-high {
        .chip(#FF3300, white);
    }

    &.cvss-medium {
        .chip(#FF6600, white);
    }

    &.cvss-low {
        .chip(#FFCC00, #333);
    }
}

.chip (@backgroundColor, @color) {
    display: inline-block;
    height: 32px !important;
    line-height: 32px !important;
    padding: 0px 8px;
    border-radius: 32px;

    background-color: @backgroundColor;
    color: @color;

    &:not(.no-hover) {
//        *:hover > &, &:hover {
//            background-color: darken(@backgroundColor, 15%);
//            color: darken(@color, 15%);
//        }
    }

    &-sm, &.sm {
        height: 28px !important;
        padding: 0px 0px;
        border-radius: 28px;

        .content {
            top: -2px;
            position: relative;

            font-size: 12px;
            vertical-align: top;
        }
    }

    &-xs, &.xs {
        height: 20px !important;
        line-height: 20px !important;
        padding: 1px 6px;
        border-radius: 20px;

        .content, .inner {
            top: -2px;
            position: relative;

            font-size: 12px;
            vertical-align: top;

            .value {
                height: 20px;
                min-width: 20px;
                border-radius: 22px;
            }
        }
    }

    &-avatar {
        padding-left: 0px;
        padding-bottom: 6px;

        img {
            border-radius: 100%;
            height: @height;

            display: inline;
            position: relative;

            margin-right: 6px;
            bottom: 6px;
        }
    }

    .content, .inner {
        display: block;
        font-size: 13px;
        font-weight: 300;
        text-align: center;
        white-space: nowrap;

        .value {
            border-radius: 100%;
            min-width: 22px;
            width: auto;
            display: inline-block;
            position: relative;
            left: -6px;
            top: 1px;
            padding-left: 3px;
            padding-right: 3px;

            &.primary {
                color: white;
                background-color: @color-primary;
            }

            &.secondary {
                color: black;
                background-color: #c9c9c9;
            }

            &.danger {
                color: white;
                background-color: @color-danger;
            }
        }

        .icon {
            position: relative;
            top: 1px;
            vertical-align: text-top;

            font-size: 125%;
            color: rgba(0, 0, 0, 0.5);
            padding: 0px 0px 0px 4px;
        }
    }
}
