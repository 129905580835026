/* Forms/Inputs */

.form-group-material {
    position: relative;
    padding-top: 20px;
    padding-left: 0px;
    min-height: 60px;
    
    * {
        .ease-transition();
        box-shadow: none;
    }
    
    select ~ label {
        font-size: 12px;
        top: 0px;        
    }
    
    input, textarea, select, .textarea {
        &:focus ~ label, &:disabled ~ label, &.active ~ label , &.textarea ~ label{
            font-size: 12px;
            top: 0px;
        }
        
        &:not([type="range"]) {
            border-bottom: solid 1px #e0e0e0;

            &:focus{
                ~ label {
                    color: @color-primary;
                }

                border-bottom: solid 2px @color-primary;
                margin-bottom: 4px;
                outline: none;
            }

            &:disabled, &:readonly {//not working???
                color: lighten(@text-color-input, 10%);
                border-bottom: dashed 1px #e0e0e0;
            }
        }
        
        display: block;
        width: 100%;

        color: @text-color-input;
        font-size: 16px;
        border: none;
        background: transparent;
        margin-bottom: 5px;
    }   
    
    p {
        font-size: 12px;
        text-align: right;
        color: #9e9e9e;

        padding-top: 5px;
        margin: 0px;
    } 
    
    label {
        position: absolute;
        top: 20px;    
        left: 2px;

        color: #9e9e9e;
        font-size: 16px;
    }
    
    input + label {
        pointer-events: none;
    }
}

/*
 * Sliders
 * */
@slider-track-height: 2px;
@slider-thumb-size: 14px;
@slider-thumb-size-active: 18px;

@slider-border-inactive: #9e9e9e;

.slider-track (@color) {
    background-color: @color;

    width: 100%;
    height: @slider-track-height;
    
    cursor: pointer;
    animate: 0.2s;   
}

.slider-thumb (@size, @border-color, @background-color: white) {
    height: @size;
    width: @size;
    border-radius: @size;            

    background-color: @background-color;
    border: @slider-track-height solid @border-color;    
    
    cursor: grab;
    animate: 0.2s;
}

input.material[type=range] {
    width: 100%;    
    background: transparent;
    
    &:focus {
        outline: none;
    }
     
    //Mozilla styles
    &::-moz-range-track {
        .slider-track(@slider-border-inactive);
    }
    
    &::-moz-range-progress {
        .slider-track(@color-primary);
    }

    &.nofill::-moz-range-progress {
        .slider-track(@slider-border-inactive);
    }
    
    &::-moz-range-thumb {
        .slider-thumb(@slider-thumb-size, @slider-border-inactive, white);
        
        &:hover {
            .slider-thumb(@slider-thumb-size, @color-primary, @color-primary);
        }
    }
    
    &.click {
        &::-moz-range-thumb {
            .slider-thumb(@slider-thumb-size-active, @color-primary, @color-primary);
        }
    }
    
    //Webkit styles
    -webkit-appearance: none;
    
    &::-webkit-slider-runnable-track {
        margin-top: 10px;
        .slider-track(@slider-border-inactive);
    }
    
    &::-webkit-slider-thumb {
        -webkit-appearance: none;
        margin-top: (@slider-track-height / 2) - (@slider-thumb-size / 2);
        
        .slider-thumb(@slider-thumb-size, @slider-border-inactive, white);
        
        &:hover {
            .slider-thumb(@slider-thumb-size, @color-primary, @color-primary);
        }        
    }
    
    &.click {
        &::-webkit-slider-thumb {
            margin-top: (@slider-track-height / 2) - (@slider-thumb-size-active / 2);
            .slider-thumb(@slider-thumb-size-active, @color-primary, @color-primary);
        }
    }
}


//
//input[type=range]::-ms-track {
//  width: 100%;
//  height: 8.4px;
//  cursor: pointer;
//  animate: 0.2s;
//  background: transparent;
//  border-color: transparent;
//  border-width: 16px 0;
//  color: transparent;
//}
//input[type=range]::-ms-fill-lower {
//  background: #2a6495;
//  border: 0.2px solid #010101;
//  border-radius: 2.6px;
//  box-shadow: 1px 1px 1px #000000, 0px 0px 1px #0d0d0d;
//}
//input[type=range]::-ms-fill-upper {
//  background: #3071a9;
//  border: 0.2px solid #010101;
//  border-radius: 2.6px;
//  box-shadow: 1px 1px 1px #000000, 0px 0px 1px #0d0d0d;
//}
//input[type=range]::-ms-thumb {
//  box-shadow: 1px 1px 1px #000000, 0px 0px 1px #0d0d0d;
//  border: 1px solid #000000;
//  height: 36px;
//  width: 16px;
//  border-radius: 3px;
//  background: #ffffff;
//  cursor: pointer;
//}
//input[type=range]:focus::-ms-fill-lower {
//  background: #3071a9;
//}
//input[type=range]:focus::-ms-fill-upper {
//  background: #367ebd;
//}

/*
 * Bootstrap 4 custom checkboxes
 * */
.c-indicator {
    border-radius: 1.5px;
    border: solid 1.25px rgba(0, 0, 0, 0.56);
    background-color: transparent;
    width: 1.25rem;
    height: 1.25rem;
    z-index: 2;
}

.c-input > .c-blur {
    .ease-transition();
    
    z-index: 0;
    width: 2.5rem;
    height: 2.5rem;
    
    opacity: 0;
    border-radius: 2.5rem;
    background-color: rgba(33, 150, 243, 0.20);
    
    position: absolute;
    top: -.6rem;
    left: -.6rem;
    display: block;
}

.c-input > input:active ~ .c-blur {
    opacity: 1;
}

.c-input > input:checked ~ .c-indicator {
    border: none;
    display: block;
    background-color: #2196f3;
}

.c-input {
    position: relative;
    display: inline;
    padding-left: 1.5rem;
    color: #555;
    cursor: pointer;
    
    input {
        position: absolute;
        z-index: -1;
        opacity: 0;
        
        &:checked ~ .c-indicator {
            color: #fff;
            background-color: #0074d9;
        }
    }
}

.c-checkbox input:checked ~ .c-indicator {
    background-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4NCjwhLS0gR2VuZXJhdG9yOiBBZG9iZSBJbGx1c3RyYXRvciAxNy4xLjAsIFNWRyBFeHBvcnQgUGx1Zy1JbiAuIFNWRyBWZXJzaW9uOiA2LjAwIEJ1aWxkIDApICAtLT4NCjwhRE9DVFlQRSBzdmcgUFVCTElDICItLy9XM0MvL0RURCBTVkcgMS4xLy9FTiIgImh0dHA6Ly93d3cudzMub3JnL0dyYXBoaWNzL1NWRy8xLjEvRFREL3N2ZzExLmR0ZCI+DQo8c3ZnIHZlcnNpb249IjEuMSIgaWQ9IkxheWVyXzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4Ig0KCSB2aWV3Qm94PSIwIDAgOCA4IiBlbmFibGUtYmFja2dyb3VuZD0ibmV3IDAgMCA4IDgiIHhtbDpzcGFjZT0icHJlc2VydmUiPg0KPHBhdGggZmlsbD0iI0ZGRkZGRiIgZD0iTTYuNCwxTDUuNywxLjdMMi45LDQuNUwyLjEsMy43TDEuNCwzTDAsNC40bDAuNywwLjdsMS41LDEuNWwwLjcsMC43bDAuNy0wLjdsMy41LTMuNWwwLjctMC43TDYuNCwxTDYuNCwxeiINCgkvPg0KPC9zdmc+DQo=);
}

.c-checkbox .c-indicator {
    border-radius: .25rem;
}

.c-indicator {
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    font-size: 65%;
    line-height: 1rem;
    color: #eee;
    text-align: center;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    background-color: #eee;
    background-repeat: no-repeat;
    background-position: center center;
    -webkit-background-size: 50% 50%;
    background-size: 50% 50%;
}
