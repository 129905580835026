@background-color: #303f9f;
@text-color: white;

.button-ghost {
    display: inline-block;
    padding: .5rem;
    margin: .5rem;

    font-size: .8rem;
    color: @background-color;
    background-color: transparent;

    border: 1px solid @background-color;
    border-radius: .25rem;

    transition: all .25s linear;

    &:hover {
        color: @text-color;
        background-color: @background-color;
    }
}
