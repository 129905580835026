.form {
    label:extend(.flex-20) {
        padding: 4px;
    };

    p:extend(.flex) {
        padding: 4px;
    };

}
