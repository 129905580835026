.float-outer-wrapper {
    position: relative;

    .float-inner-wrapper {
        width: 100%;
        position: absolute;

        .float {
            position: relative;

            &-fixed {
                position: fixed;
            }
        }
    }
}
