@import "progress";

.well {
    background: whitesmoke;
    margin: 4px;
    padding: 8px;
    border-radius:4px;
    font-size: inherit;
}

blockquote {
    margin:revert;
}