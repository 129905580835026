.file-upload {
    padding: .5rem;
    border-radius: .25rem;
    border: solid 1px #808080;
    
    background-image: linear-gradient(45deg, #808080 25%, transparent 25%), 
                        linear-gradient(-45deg, #808080 25%, transparent 25%), 
                        linear-gradient(45deg, transparent 75%, #808080 75%), 
                        linear-gradient(-45deg, transparent 75%, #808080 75%);
    background-size: 10px 10px;
    background-position: 0 0, 0 5px, 5px -5px, -5px 0px;
}