//Render fix for modals
.md-datepicker-calendar-pane {
    z-index: 12000;
}

md-input-container {
    //Removes the error message from an input container for a more compact form
    &.no-error, &[no-error] {
        .md-errors-spacer {
            display: none;
        }
        margin-bottom:0px;
    }

    &.no-border .md-input {
        &, &:focus {
            border: none;
        }
    }
}
